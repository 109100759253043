import {Component, Input, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Estado, Field, Gasto, ImportacionDocumento, Menu, ModulePermissions, Opciones, Pregunta} from '../../../interfaces/interfaces';
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActionType, InputType, PaymentStatusType, PreguntaType, StatusType, ValidForm} from '../../../enums/enums';
import {environment} from "../../../environments/environment.prod";
import Swal from "sweetalert2";
import {NgForm} from "@angular/forms";
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {HttpEventType} from '@angular/common/http';
declare var KTUtil;
declare var FormValidation;
declare var $: any;

declare var KTImageInput;
declare var KTDialog;

@Component({
  selector: 'app-add-or-edit-form',
  templateUrl: './add-or-edit-form.component.html',
  styleUrls: ['./add-or-edit-form.component.css']
})
export class AddOrEditFormComponent implements OnInit {
  @Input() module_id;
  @Input() moduleName;
  @Input() table;
  @Input() id;

  fields: Field[];
  InputType = InputType;
  ActionType = ActionType;
  accion:string = ActionType.CREATE;
  loading_actualizar_cliente = false;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  validatedFields = [];
  modulePermissions: ModulePermissions[];
  response: any;
  url = environment.url;
  canEdit: boolean;
  currentMenu: Menu;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  estados: Estado[];
  loadingGuardarEstado: boolean;
  newPregunta: Pregunta;
  preguntas: Pregunta[];
  detalles: any;
  opciones:any;
  editOpcion: boolean;
  hasLocation: boolean = false;

  options: any;
  //@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>
  markerPositions: any = [];
  markerOptions: google.maps.MarkerOptions = {draggable: false};


  codigoCliente: any;
  usuarios = [];
  @ViewChild(DataTableDirective, {static: false})
  datatableElementDocument: DataTableDirective;
  dtTriggerDocument = new Subject();

  file:any;
  single_business = environment.single_business;
  MultipleData:any;
  selecteds:any;
  values:any;
  constructor(public req: RequestService, public nav: Nav) {}

  async ngOnInit() {
    if (this.module_id != null) {
      await this.generateForm();
      if (this.table === 'roles'){
        await this.permissionList();
        if (this.id) {
          for (let permission of this.modulePermissions) {
            for (let action of permission.permissions) {
              for (let roleAction of this.response.response.permissions) {
                if (roleAction.permission_id === action.id) {
                  action.value = true;
                }
              }
            }
          }
        }
      }
      this.currentMenu      = this.req.getMenu(this.table);
      this.canEdit          = this.currentMenu.edit;
      if(this.accion === ActionType.EDIT && !this.currentMenu.edit && !this.currentMenu.read){
        this.nav.push('search', {module: this.table});
      }

    }

    this.loading = false;

  }

  async searchEncuestas(fechaInicial?,  fechaFinal?){
    const url = 'respuestas?empresa=' + this.req.loggedUser.empresa_selected.id + '&fecha_inicial=' + fechaInicial + '&fecha_final=' + fechaFinal + '&usuario=' + this.usuario  + '&cliente=' + this.codigoCliente ;
    return await this.req.GET(url);;
  }

  async guardar(form: NgForm){
    form.form.value.table = this.table;
    form.form.value.permissions = this.modulePermissions;
    if (form.valid){
      if (this.table === 'roles'){
        form.form.value.permissions = this.modulePermissions;
      }
      // if (this.table !== 'empresas' && this.table !== 'roles') {
      //   form.form.value.empresa = this.req.loggedUser.empresa_selected.id;
      // }
      let url = 'save';
      if (this.accion === ActionType.EDIT){
        url = 'update';
        form.form.value.id = this.id;
      }

      // return;
      this.loadingSave  = true;
      const response    = await this.req.POST(url, form.form.value);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else {
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        Swal.fire(
            '',
            '<p>Se ha ' + accion + ' satisfactoriamente un ' + this.moduleName + '.</p>',
            'success'
        );
        if (this.table !== 'encuestas' || this.id !== 1) {
          this.nav.push('search', {module: this.table});

        }
      }
    }
  }
  async hasLocations(){
    this.hasLocation = this.fields.some(p => p.field == 'latitude');
    if(!this.hasLocation) { return; }
    const datos = {
      lat: parseFloat(this.response.response.latitude),
      lng: parseFloat(this.response.response.longitude),
    };
    console.log(datos);
    this.markerPositions.push(datos);
    this.options =  {
      center: datos,
      zoom: 11,
      clickable: true
    };
  }
  async generateForm(){
    this.fields = await this.req.GET('module_fields?module=' + this.module_id);
    if (this.id) {
      this.accion = ActionType.EDIT;
      this.response = await this.req.GET('module_data?module=' + this.table + '&id=' + this.id);
      await this.hasLocations();

    }
    for (const field of this.fields){
      if (field.type === InputType.SELECT_DROPDOWN) {
        field.details.options = Object.keys(field.details.options).map((key) => {
          if (this.id) {
            field.value = this.response.response[field.details.column];
          }
          return { value: key, text:field.details.options[key]};
        });
        
      }else if(field.type === InputType.RELATIONSHIP){
        field.options = await this.req.GET_URL(environment.relation_url + this.table + '/relation?type=' + field.field);
        if (this.id) {
          field.value = this.response.response[field.details.column];
        }
      }else if(field.type === InputType.SELECT_MULTIPLE){
        var get = field.details.get;
        const responseDias = await this.req.GET(get);

        if (responseDias.responseCode == "success") {
          field.details.MultipleData = responseDias.response;
          if (this.id) {
            field.details.selecteds = responseDias.response.map(p=>p);
            field.details.values = field.details.selecteds.map(p=>p.id);
          }
        }
      }
      if (this.id) {
        const $this = this;
        Object.keys(this.response.response).map((key) => {
          if (field.field === key && field.type !== InputType.RELATIONSHIP){
            field.value = $this.response.response[key];
          } else if (field.display_name.toLowerCase() === key){
            field.value = $this.response.response[key];
          }
        });
      }
    }
  }
  async permissionList(){
    const response = await this.req.GET('modules_options');
    this.modulePermissions = response.response;
  }

  async uniqueValue(field: Field) {
    if(field.details.unique) {
      const response = await this.req.GET('validate_exist?module=' + this.table + '&field=' + field.field + '&value=' + field.value + '&id=' + this.id);
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        field.value = null;
        await this.validation.validate();
        return;
      }
    }
  }

  resetPregunta(){
    this.preguntas = !this.preguntas ? [] : this.preguntas;
    this.newPregunta = new Pregunta();
    this.newPregunta.obligatory = 'No';
    this.newPregunta.order = this.preguntas.length + 1;
    this.newPregunta.opciones = [];
    this.newPregunta.hash = this.req.generateRamdomString(10);
    this.editOpcion = false;
  }

  changeType() {
    if (this.newPregunta.type === PreguntaType.MULTIPLE || this.newPregunta.type === PreguntaType.UNICA){
      if (this.newPregunta.opciones.length === 0) {
        this.newPregunta.opciones.push(new Opciones());
      }
    }
  }
  addOption(){
    this.newPregunta.opciones.push(new Opciones());
  }

  editPregunta(pregunta){
    this.editOpcion = true;
    this.newPregunta = this.req.copyObject(pregunta);

  }
  llenarObjectoPregunta(pregunta){
    pregunta.title      = this.newPregunta.title;
    pregunta.type       = this.newPregunta.type;
    pregunta.order      = this.newPregunta.order;
    pregunta.comment    = this.newPregunta.comment;
    pregunta.obligatory = this.newPregunta.obligatory;
    pregunta.opciones   = this.newPregunta.opciones;
  }
  guardarOpcion(){
    if (!this.editOpcion) {
      this.preguntas.push(this.newPregunta);
    }else{
      if (this.newPregunta.id){
        for (const pregunta of this.preguntas){
          if (pregunta.id === this.newPregunta.id){
            this.llenarObjectoPregunta(pregunta);
            break;
          }
        }
      }else{
        for (const pregunta of this.preguntas){
          if (pregunta.hash === this.newPregunta.hash){
            this.llenarObjectoPregunta(pregunta);
            break;
          }
        }
      }
    }
    this.resetPregunta();
  }

  nuevaPregunta() {
    this.editOpcion = false;
    this.resetPregunta();
  }

  eliminarOpcion(opcion){
    this.req.removeFromList(this.newPregunta.opciones,opcion);
  }
  eliminarPregunta(pregunta: Pregunta) {
    
  }
  currentDelete:any;
  usuario: any;
  deleteTable: any;
  setCurrentDelete(data: any, table?) {
    this.deleteTable = table;
    this.currentDelete = data;
  }
  deleteRecord() {
    this.req.removeFromList(this.preguntas, this.currentDelete);
  }

  async actualizarClientes() {
    this.loading_actualizar_cliente = true;
    await this.req.POST('actualizar_clientes', {empresa_id: this.req.loggedUser.empresa_selected.id});
    Swal.fire(
        '',
        '<p>Se han actualizado los clientes satisfactoriamente.</p>',
        'success'
    );
    this.loading_actualizar_cliente = false;

  }

  goToURL(position) {
    window.open(
        'https://maps.google.com/?q=' + position.lat + ',' + position.lng,
        '_blank' // <- This is what makes it open in a new window.
    );
  }
  openInfoWindow(marker: MapMarker, windowIndex: number) {
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
    //this.infoWindow.open(marker);
  }
  hasRowPermisos(row){
    for(let col of row.permissions){
      if(col.value == false){ return false; }
    }
    return true;
  }
  hasAllPermisos(){
    for(const row of this.modulePermissions){
      if(this.hasRowPermisos(row) == false){ return false; }
    }
    return true;
  }
  setAllPermisos(val){
    for(const row of this.modulePermissions){ this.setRowPermisos(row, val); }
  }
  setRowPermisos(row, val){
    for(let col of row.permissions){
      col.value = val;
    }
  }
  togglePermisos(permisos){
    if(typeof permisos == 'string' && permisos == 'all'){
      const val = !this.hasAllPermisos();
      this.setAllPermisos(val);
    } else {
      const val = !this.hasRowPermisos(permisos);
      this.setRowPermisos(permisos, val);
    }
  }

  loaded = false;
  changeImage(){
    if(!this.loaded){
      this.loaded = true;
      for(let field of this.fields){
        if(field.type == InputType.IMAGE){
          let image = new KTImageInput('kt_image_'+field.field);
          //img_default = environment.default_avatar_boy;
          image.on('change', async (data) => {
            field.value = String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
          });
        }
      }
    }
  }
  generateCode() {
    for(let field of this.fields) {
      if (field.display_name === 'Codigo') {
        field.value = this.req.generateRamdomString(6);
      }
    }
  }
}

