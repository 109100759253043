import { Component, OnInit } from '@angular/core';
import {LoteriaCombinacion, TipoJuego} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment.prod';
import { FormControl, FormGroup } from '@angular/forms';
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-loteria-add-or-edit',
  templateUrl: './loteria-add-or-edit.component.html',
  styleUrls: ['./loteria-add-or-edit.component.css']
})
export class LoteriaAddOrEditComponent implements OnInit {
  modulo = 'loteria';
  table   = 'loterias'
  img_default = environment.default_avatar_boy;
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  loteria:TipoJuego = new TipoJuego();
  // loteria_combinacion: LoteriaCombinacion = new LoteriaCombinacion();
  ActionType: ActionType;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  tipoJuegosData:any;
  tipo_juegos:any;
  selecteds:any = [];
  combinaciones_exist:boolean = false;
  combinaciones:any = [];
  combinations_value:any = [];
  current:any = [];


  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      this.loteria.id = parseInt(params.get('id'));

      // Obtener las posiciones
      const parms = {
        table: 'tipo_juegos'
      };
      
      this.tipoJuegosData = await this.req.POST('data', parms)??[];
      
      if(this.loteria.id){
        const response = await this.req.GET('loteria?module=' + this.table + '&id=' + this.loteria.id);
        this.selecteds = response.response.tipo_juego;
        this.loteria = response.response;
        this.tipo_juegos = this.selecteds.map(p=>p.id);
        this.accion = ActionType.EDIT;  

        // Cargar combinaciones
        if (this.selecteds.length > 0) {
          await this.loadCombinations();
          // Cargar valor de combinaciones
          await this.loadCombinacionesValue();
        }

        
      }
      if(ActionType.CREATE === this.accion) {
        this.loteria.estado = 'Activo';
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_loteria_form'),
        {
          fields: {
            nombre: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerida.'
                }
              }
            },
            estado: {
              validators: {
                notEmpty: {
                  message: 'Estado es requerido.'
                }
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
   

  async guardar(){
    const status = await this.validation.validate();
    // this.loteria.logo = $('input[name=image_logo]').value;
    // console.log(this.tipo_juegos)
    // return;
    var data = {
      "loteria":this.loteria,
      "tipo_juego":this.tipo_juegos,
      "combinaciones_valor":this.combinations_value
    };

    if (ValidForm.VALID === status){
      this.loadingSave  = true;
      const response    = await this.req.POST('save_loteria', data);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('loteria');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente unc ' + this.modulo + '.</p>',
          'success'
        );


      }
    }
  }

  async loadCombinations(){
    for (let i = 0; i < this.selecteds.length; i++) {
      var element = this.selecteds[i];
      const res = await this.req.GET('tipo_juego?module=' + this.table + '&id=' + element.id);
      
      this.combinaciones.push({"combinaciones":res.response});

    }
    
    if (this.combinaciones) {
      this.combinaciones_exist = true;
    }

    // console.log(this.combinaciones)
  }

  loaded = false;
  changeImage(){
    if(!this.loaded){
      this.loaded = true;
      let image = new KTImageInput('kt_image_logo');
      console.log(image)
      //img_default = environment.default_avatar_boy;
      image.on('change', async (data) => {
        console.log(data)
        $('input[name=image_logo]').value = String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
        // this.loteria.logo =  String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
      });
    }
  }
 

  findCombinations (high, total, low = 1) {
    if (total === 0) {
      this.combinations_value.push([...this.current])
      return this.combinations_value
    }
    for (let i = low; i <= high; i++) {
      this.current.push(i)
      this.findCombinations(high, total - 1, i + 1)
      this.current.pop()
    }
    return this.combinations_value
  }

  valueChange(event){
    // console.log('a')
    var valor = 0;
    var id_combinacion = parseInt(event.srcElement.combinacion);
    var id_input = 'input'+id_combinacion;
    var inputs = $('.input-valor');

    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i];
      
      if (element.id === id_input) {
        valor = element.value
      }
      
    }

    // valor = input;

    var modificado = false;

      for (let x = 0; x < this.combinations_value.length; x++) {
        const combi = this.combinations_value[x];

        if (combi.id_combinacion == id_combinacion) {
          combi.valor = valor;
          modificado = true;
          break;
        }
        
      }

      if(!modificado){
        this.combinations_value.push({
          id_combinacion,
          valor
        });
      }
   

    console.log(this.combinations_value)
  }


  async loadCombinacionesValue(){

    for (let x = 0; x < this.loteria.combinaciones_valor.length; x++) {
      const element = this.loteria.combinaciones_valor[x];

      this.combinations_value.push({
        id_combinacion:element.combinacion_id,
        valor:element.valor
      })
    }

    for (let c = 0; c < this.combinaciones.length; c++) {
      const combinacion = this.combinaciones[c];
      for (let y = 0; y < combinacion.combinaciones.combinaciones.length; y++) {
        const combi = combinacion.combinaciones.combinaciones[y];
        
        for (let i = 0; i < this.combinations_value.length; i++) {
          const valor = this.combinations_value[i];
    
          if (combi.id === valor.id_combinacion) {
            combi.valor = valor.valor;
          }
          
        }
      }
    }
  }

  changeCombination(){
    // console.log(event)
  }
}
