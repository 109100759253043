import { Component, OnInit } from '@angular/core';
import {Estado, Prestamo} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment.prod';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from "moment";

declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-prestamos-add-or-edit',
  templateUrl: './prestamos-add-or-edit.component.html',
  styleUrls: ['./prestamos-add-or-edit.component.css']
})
export class PrestamosAddOrEditComponent implements OnInit {
  modulo = 'prestamo';
  table   = 'prestamos'
  img_default = environment.default_avatar_boy;
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  ActionType: ActionType;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  prestamo: Prestamo = new Prestamo();
  cuotas:any = [];
  cuotas_active:any = [];
  estados: Estado = new Estado();

  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      this.prestamo.id = parseInt(params.get('id'));

      this.estados = await this.req.GET('prestamo_estados');
      if(this.prestamo.id){
        const response = await this.req.GET('prestamo?module=' + this.table + '&id=' + this.prestamo.id);
        this.accion = ActionType.EDIT;  
        
        if(response.responseCode != "success") return;
        
        this.prestamo = response.response;
        if(this.prestamo.estado_id == '2') this.loadCuotas(this.prestamo.id);
      }
      if(ActionType.CREATE === this.accion) {
        
      }

      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);

      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_prestamo_form'),
        {
          fields: {
            nombre: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerida.'
                }
              }
            },
            estado: {
              validators: {
                notEmpty: {
                  message: 'Estado es requerido.'
                }
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }

  async estadoChanged(event){
    if(event == 2){
      await this.generarCuotas();
    }
  }
  
  generarCuotas(){
    // Si la cantidad de días está entre 7 a 15 días la frecuencia de pago será semanal (Cada 7 días).
    // Si la cantidad de días está entre 30 a 90 días la frecuencia de pago será quincenal (Cada 15 días).
    var dias = this.prestamo.dias;
    var frecuencia = 0;
    var cuotas_t = [];
    var n_pagos = 1;
    var cuota = this.prestamo.monto_final;
    var today = moment().format('YYYY/MM/DD');
    var date = today;

    if(dias <= 15){
      frecuencia = 7;
    }else if(dias > 15){
      frecuencia = 15;
    }

    n_pagos = Math.trunc(dias / frecuencia);

    for (let i = 0; i < n_pagos; i++) {
      date = moment(date).add(frecuencia, 'days').format('YYYY/MM/DD'); 
      var pagar = parseFloat(cuota) / n_pagos;
      cuotas_t.push({
        fecha:date,
        monto:pagar
      });
    }

    this.cuotas = cuotas_t;
  }

  async guardar(){
    var data = {
      "prestamo":this.prestamo,
      "cuotas":this.cuotas,
    };

    this.loadingSave  = true;
    const response    = await this.req.POST('save_prestamo', data);
    this.loadingSave  = false;
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      return;
    }else{
      const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
      Swal.fire(
        '',
        '<p>Se ha ' + accion + ' satisfactoriamente unc ' + this.modulo + '.</p>',
        'success'
      );
      this.nav.push('prestamos')
    }
  }

  async loadCuotas(prestamo_id:number){
    const response = await this.req.POST('prestamo_cuotas',{id: prestamo_id});

    if(response.responseCode == 'success'){
      this.cuotas_active = response.response;
    }
  }





}
