<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea una nueva ' + modulo : 'Edita una ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_loteria_form" autocomplete="off">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <input type="hidden" name="logo" accept=".png, .jpg, .jpeg" [(ngModel)]="loteria.logo"/>
              <div class="image-input" id="kt_image_logo" style="  width: 120px;display: block;margin-bottom: 20px;">
                <div class="image-input-wrapper" style="background-image: url({{loteria.logo}})"></div>
                <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change image">
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input type="image" (click)="changeImage()" [(ngModel)]="loteria.logo" name="image_logo" accept=".png, .jpg, .jpeg"/>
                  <input type="hidden" name="image_logo_remove"/>
                </label>
                <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel image">
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
        </div>
        <div class="col-lg-6">
          <label>Nombre:</label>
          <input [(ngModel)]="loteria.nombre" type="text" name="nombre" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del del tipo de juego"/>
          <span class="form-text text-muted">Por favor, Introduzca el nombre del tipo de juego.</span>
        </div>
        <div class="col-lg-6">
            <label>Tipos de juegos:</label>
            <app-select [data]="tipoJuegosData" [multiple]="true" *ngIf="!loading" text="nombre" (value)="tipo_juegos=$event" [selecteds]="selecteds"></app-select>
            <span class="form-text text-muted">Por favor, Introduzca los tipos de juegos de esta loteria.</span>
          </div>
        <div class="col-lg-6">
          <label>Estado:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="loteria.estado" type="radio" name="estado"  value="Activo"/>
              <span></span>
              Activo
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="loteria.estado" type="radio" name="estado" value="Inactivo"/>
              <span></span>
              Inactivo
            </label>
          </div>
          <span class="form-text text-muted">Por defecto el estado viene activo, pero lo puede cambiar!</span>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-12" style="margin: auto;" *ngIf="combinaciones_exist">
            <my-tabs>
              <my-tab *ngFor="let combinacion of combinaciones" [tabTitle]="combinacion.combinaciones.nombre">
                <table class="table row-border hover">
                  <thead class="bg-gray-100" *ngIf="combinacion.combinaciones">
                  <tr>
                    <th>Combinaciones</th>
                    <th>Valor por peso apostado</th>
                    <!-- <th>Acciones</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of combinacion.combinaciones.combinaciones" id="{{ data.id }}">
                    <td>
                      <span class="posicion" *ngFor="let item of data.combinaciones_details; let i = index">
                        <kbd>{{ item.nombre }}</kbd> <span *ngIf="data.combinaciones_details.length != i+1"> - </span>
                      </span>
                    </td>
                    <td>
                      <input type="number" class="form-control input-valor" id="input{{ data.id }}"
                       data-combinacion="{{ data.id }}" name="valor" [placeholder]="'0.00'" (change)="valueChange($event)"
                       [value]="data.valor">
                    </td>
                    <td style="text-align: center; display: none;" >
                      <a href="javascript:;" *ngIf="(currentMenu && currentMenu.edit) || req.loggedUser.role_id === 1" (click)="edit(data.id)" class="btn btn-sm btn-light-warning btn-icon" title="Editar">
                        <i class="la la-edit"></i>
                      </a>
            
                      <a href="javascript:;"
                         style="margin-left:5px;" (click)="setCurrentDelete(data)"
                         data-toggle="modal" data-target="#deleteRecord"
                         *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
                         class="btn btn-sm btn-light-danger btn-icon" title="Eliminar">
                        <i class="la la-remove"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </my-tab>
            </my-tabs>
        </div>
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>