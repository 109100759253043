import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {SearchComponent} from './pages/search/search.component';
import {AddOrEditComponent} from './pages/add-or-edit/add-or-edit.component';
import {UsuariosComponent} from './pages/usuarios/usuarios.component';
import {UsuariosAddOrEditComponent} from './pages/usuarios-add-or-edit/usuarios-add-or-edit.component';
import {EditProfileComponent} from './pages/edit-profile/edit-profile.component';
import {SelectEmpresaComponent} from './pages/select-empresa/select-empresa.component';
import { TipoJuegoComponent } from './pages/tipo-juego/tipo-juego.component';
import {TipoJuegoAddOrEditComponent} from './pages/tipo-juego-add-or-edit/tipo-juego-add-or-edit.component';
import {LoteriaComponent} from './pages/loteria/loteria.component';
import {LoteriaAddOrEditComponent} from './pages/loteria-add-or-edit/loteria-add-or-edit.component'
import { PrestamosComponent } from './pages/prestamos/prestamos.component';
import { PrestamosAddOrEditComponent } from './pages/prestamos-add-or-edit/prestamos-add-or-edit.component';
import { PagosComponent } from './pages/pagos/pagos.component';
import { ClientsComponent } from './pages/clients/clients.component';
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'add-or-edit',
    component: AddOrEditComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },

  {
    path: 'usuarios/add-or-edit',
    component: UsuariosAddOrEditComponent
  },
  {
    path: 'profile',
    component: EditProfileComponent
  },
  {
    path: 'seleccione-empresa',
    component: SelectEmpresaComponent
  },
  {
    path:'prestamos',
    component: PrestamosComponent
  },
  {
    path:'prestamos/add-or-edit',
    component: PrestamosAddOrEditComponent
  },
  {
    path:'pagos',
    component: PagosComponent
  },
  {
    path:'clientes',
    component: ClientsComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
