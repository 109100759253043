export const environment = {
  production: true,
  url: 'https://fintech.devcreativo.pro/public/api/',
  images_url: 'https://fintech.devcreativo.pro/public/storage/app/public/',
  relation_url: 'https://fintech.devcreativo.pro/public/admin/',
  error_page: 'dashboard',
  default_avatar_boy: 'assets/media/users/default.png',
  default_avatar_girl: 'assets/media/users/default-girl.png',
  project_name: 'PROXIMA',
  single_business: true

};


// http://127.0.0.1:8000
// https://fintech.devcreativo.pro/public