import {Component, NgZone, OnInit} from '@angular/core';
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading:boolean = false;
  logo:string = '';
  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) {

  }

  ngOnInit(): void {
    if(this.req.loggedUser.id){
      this.nav.push('dashboard');
    }
  }

  async login(){
    if (this.req.loggedUser.email && this.req.loggedUser.contrasena) {
      this.loading = true;
      const response = await this.req.POST('login', this.req.loggedUser);
      this.loading = false;
      if (response.error) {
        Swal.fire(
          '',
          response.error,
          'error'
        );
        return;
      }
      this.req.loggedUser = response.response;
      this.req.setLoggedUser(response.response);
      console.log(this.req.loggedUser.empresas.length);
      if (!this.req.loggedUser.empresas || this.req.loggedUser.empresas.length > 1){
        this.nav.push('seleccione-empresa');
      }else {
        this.req.loggedUser.empresa_selected = this.req.loggedUser.empresas[0];
        this.req.setLoggedUser(this.req.loggedUser);
        if (this.req.loggedUser.reset_password === 1) {
          this.nav.push('dashboard');
        } else {
          Swal.fire(
              '',
              'Por favor cambie su contraseña.',
              'warning'
          );
          this.nav.push('profile');
        }
      }
    }
  }
}
