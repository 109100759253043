import { Component, OnInit } from '@angular/core';
import {Nav} from "../../../services/nav.service";
import {Menu} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {ActivatedRoute} from "@angular/router";
import * as $ from 'jquery';
@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.css']
})
export class PrincipalMenuComponent implements OnInit {
  inDashboard: boolean;
  constructor(public nav: Nav, public req: RequestService, private actRoute: ActivatedRoute) { }
  async ngOnInit() {
    if(window.location.href.indexOf('dashboard') > -1){
      this.inDashboard  = true;
    }

    if(!this.req.menues) {
      const response      = await this.req.GET('menu?loggedUser=' + this.req.loggedUser.id);
      
      this.req.menues = response.response;
      this.req.setMenu(this.req.menues)
    }
    this.resetMenu();

  }
  openMenu(menu:Menu){
    if(menu.childrens){
      menu.opened  = !menu.opened;
    }
  }
  go(page, params){
    const module = ['usuarios', 'prestamos', 'pagos', 'clientes'];

    if(params.module && !module.includes(params.module)) {
      this.nav.push(page, params);
    }else{
      switch (params.module) {
      case 'usuarios':
        this.nav.push('usuarios', {module: params.module});
        break;
      case 'prestamos':
        this.nav.push('prestamos', {module: params.module});
        break;
      case 'pagos':
        this.nav.push('pagos', {module: params.module});
        break;
      case 'clientes':
        this.nav.push('clientes', {module: params.module});
        break;
    }
    }
  }
  gotoSelectEmpresa(){
    this.nav.push('seleccione-empresa');
  }
  resetMenu(){
    for (let menu of this.req.menues) {
      menu.active = false;
      menu.opened = false;

      if (menu.childrens) {
        for (let child of menu.childrens) {
          child.active = false;
        }
      }
    }
    this.actRoute.queryParamMap.subscribe(async params => {
      const currentUrl = params.get('module');
      for (let menu of this.req.menues) {
        if (currentUrl === menu.url && currentUrl != null) {
          menu.active = true;
          menu.opened = true;
          return;
        }
        if (menu.childrens) {
          for (let child of menu.childrens) {
            child.active = false;
            if (currentUrl === child.url && currentUrl != null) {
              child.active = true;
              menu.opened = true;
              return;
            }
          }
        }
      }
    });
  }
  async toggleUser(){
    this.toggleAny("#kt_body", "topbar-mobile-on", "#__kt_header_mobile_topbar_toggle");
    //this.toggleAny("#__kt_header_mobile_topbar_toggle", "active");
  }
  async toggleMenu(){
    this.toggleAny("#kt_aside", "aside-on", "#__kt_header_mobile_toggle");
    //this.toggleAny("#__kt_header_mobile_toggle", "active");
  }
  async toggleAny(id: string, clase: string, active: string){
    const selector = $(id);
    const cond = $(id).hasClass(clase);
    if(cond){
      selector.removeClass(clase);
      $(active).removeClass("active");
    } else {
      selector.addClass(clase);
      $(active).addClass("active");
    }
  }
}
