import { Component } from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {environment} from '../environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = environment.project_name;
  inLogin = false;
  constructor(private router: Router) {
    if(window.location.href.indexOf('login') > -1){
      this.inLogin = true;
    }else{
      this.inLogin = false;
    }
    console.log(this.inLogin)
  }

}
