import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Categoria, Cliente, Documento, Field, Menu, Module} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {NgForm} from "@angular/forms";
import {Nav} from "../../../services/nav.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {InputType} from "../../../enums/enums";
import {environment} from "../../../environments/environment.prod";
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import * as moment from "moment";
import { ActivatedRoute, Router } from '@angular/router';
declare var toastr;
declare var KTImageInput;

@Component({
  selector: 'app-prestamos',
  templateUrl: './prestamos.component.html',
  styleUrls: ['./prestamos.component.css']
})
export class PrestamosComponent implements OnInit {
  module_id;
  moduleName;
  table;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger= new Subject();
  fields: Field[];
  datas:any;
  InputType = InputType;
  cliente: Cliente = new Cliente();
  categoria:Categoria = new Categoria();
  currentMenu: Menu;
  complete_load_clientes: number = 0;
  file_load_clientes:any;
  importCSV = false;
  downloadCSV = false; 
  module:Module;
  constructor(public req: RequestService, private nav: Nav, private router: Router, private actRoute: ActivatedRoute) {

  }
  async ngOnInit() {

    this.module_id = 125
    this.moduleName = "prestamos";
    this.table = 'prestamos';
   
    this.importCSV = (this.table !== 'empresas');
    this.downloadCSV = (this.table !== 'empresas' && this.table != 'encuestas');

    if(this.module_id != null) {
      this.fields = await this.req.GET('module_fields?module=' + this.module_id);
      for (let field of this.fields){
        if (field.type === InputType.SELECT_DROPDOWN) {
          field.details.options = Object.keys(field.details.options).map(function (key) {
            return { value: key, text:field.details.options[key]}
          });
        }else if(field.type === InputType.RELATIONSHIP){
          field.options = await this.req.GET_URL(environment.relation_url + this.table + '/relation?type=' + field.field);
        }
      }
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false
    };
    
    this.currentMenu = this.req.getMenu(this.table);
    this.datas = await this.req.POST('data', {table: this.table});

    this.fields.map(f => {
      if(f.type === 'relationship'){
        f.field = f.field.toLowerCase().split('_')[2];
      }
      return f;
    });
    const campos = this.fields.filter(f => f.type == "relationship");
    this.datas.map(function(data){
      for(let x= 0; x < campos.length; x++)
      {
        const val = campos[x].options.find(c => c.id == data[campos[x].details.column]);
        data[campos[x].field] = (val != undefined) ? val.text : "";
      }
      return data;
    });
    this.dtTrigger.next();
    this.upload_clientes();
  }

  buscar(form: NgForm){
    this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
      this.datas = null;
      dtInstance.destroy();
      form.form.value.table = this.table;
      form.form.value.empresa = this.req.loggedUser.empresa_selected.id;
      this.datas = await this.req.POST('data', form.form.value);

      this.fields = await this.req.GET('module_fields?module=' + this.module_id);
      for (let field of this.fields){
        if (field.type === InputType.SELECT_DROPDOWN) {
          field.details.options = Object.keys(field.details.options).map(function (key) {
            return { value: key, text:field.details.options[key]}
          });
        }else if(field.type === InputType.RELATIONSHIP){
          field.options = await this.req.GET_URL(environment.relation_url + this.table + '/relation?type=' + field.field);
        }
      }

      this.fields.map(f => {
        if(f.type === 'relationship'){
          f.field = f.field.toLowerCase().split('_')[2];
        }
        return f;
      });

      const campos = this.fields.filter(f => f.type == "relationship");
      this.datas.map(function(data){
        for(let x= 0; x < campos.length; x++)
        {
          const val = campos[x].options.find(c => c.id == data[campos[x].details.column]);
          data[campos[x].field] = (val != undefined) ? val.text : "";
        }
        return data;
      });
      this.dtTrigger.next();
    });
  }
  edit(id) {
    this.nav.push('prestamos/add-or-edit', {module: this.table, id: id});
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  async refrescar(){
    window.location.reload();
  }
  async upload_clientes(){
      this.file_load_clientes = new KTImageInput('kt_image_2');
      this.file_load_clientes.on('change', async (data)=>{
        const file = data.input.files[0];

        const ext   = file.name.split(".")[1];
        if(ext !== 'csv'){
          toastr.error('Plantilla no es tipo csv. Por favor verifique que sea la plantilla descargada del sistema.');
          return;
        }
        let documento = new Documento();
        documento.size = (file.size/1000).toFixed(1);
        documento.name = file.name;
        let fileFormData = new FormData();
        fileFormData.append('file', file);
        fileFormData.append('size', ((file.size/1000).toFixed(1)).toString());
        fileFormData.append('name', file.name);
        fileFormData.append('extension', ext);
        this.req.http.post(environment.url + 'upload_clientes', fileFormData, {
          reportProgress: true,
          observe: 'events'
        }).subscribe(async resp => {
          if (resp.type === HttpEventType.Response) {
            this.complete_load_clientes = 0;
            // @ts-ignore
            $("#cargar_archivo_clientes").modal('hide');
            toastr.success('Has cargado los clientes satisfactoriamente!');
            this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
              this.datas = await this.req.POST(this.table, {});
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          }else if (resp.type === HttpEventType.UploadProgress) {
            this.complete_load_clientes = Math.round(100 * resp.loaded / resp.total);
          }else{
            this.complete_load_clientes = 5;
          }
        });
      });
  }
  async validateExist() {
    const response = await this.req.GET('validate_exist?module=' + this.table + '&field=rnc_cedula&value=' + this.cliente.rnc_cedula + '&id=' + this.cliente.id);
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      this.cliente.rnc_cedula = null;
      return;
    }
  }
  async validateExistCategoryName() {
    const response = await this.req.GET('validate_exist?module=' + this.table + '&field=nombre&value=' + this.cliente.rnc_cedula + '&id=' + this.cliente.id);
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      this.cliente.rnc_cedula = null;
      return;
    }
  }
  loadingSave:boolean;
  async guardar(){
    if (!this.cliente.nombre || !this.cliente.rnc_cedula){
      Swal.fire(
        '',
        'Todos los campos son obligatorios',
        'error'
      );
      return;
    }
    this.cliente.table = 'clientes';
    this.loadingSave  = true;
    const response    = await this.req.POST('save',this.cliente);
    this.loadingSave  = false;
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      return;
    }else{
      $("#cancelarCrearServicio").click();
      this.nav.push("/clientes/add-or-edit", {module: this.table, id: response.response.id});
    }
  }

  async guardarCategoria(){
    if(!this.categoria.nombre){
      Swal.fire(
        '',
        'Todos los campos son obligatorios',
        'error'
      );
      return;
    }
    this.categoria.table = 'categorias';
    this.loadingSave  = true;
    const response    = await this.req.POST('save',this.categoria);
    this.loadingSave  = false;
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      return;
    }else{
      $("#cancelarAddEstado").click();
      this.nav.push("add-or-edit", {module: this.table, id: response.response.id});
    }
  }

  deleteRecord() {
    this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
      this.datas = this.req.removeFromList(this.datas, this.currentDelete);
      dtInstance.destroy();
      this.dtTrigger.next();
      this.req.POST("delete_record", {table: this.table, id: this.currentDelete.id});
      Swal.fire(
        '',
        'Registro eliminado satisfactoriamente.',
        'success'
      );
    });
  }
  currentDelete:any;
  setCurrentDelete(data: any) {
    this.currentDelete = data;
  }

  deleteAllRecords() {
    this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
      this.req.POST("delete_all_records", {table: this.table, empresa: this.req.loggedUser.empresa_selected.id});
      this.datas = [];
      Swal.fire(
        '',
        'Registros eliminados satisfactoriamente.',
        'success'
      );
    });
  }

}
