import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../environments/environment.prod";
import {Field, Menu, Usuario} from '../interfaces/interfaces';
import * as moment from "moment";
// @ts-ignore
@Injectable()
export class RequestService {
  loggedUser:Usuario;
  menues:Menu[];
  PROJECT_NAME = environment.project_name;
  constructor(public http: HttpClient) {
    this.load();
  }
  load() {
    this.loggedUser = this.getItem('loggedUser') ? this.getItem('loggedUser') : new Usuario();
    this.menues = this.getItem('menues') ? this.getItem('menues') : this.menues;
  }
  async POST_FORM_DATA(url, formData){
    return await this.http.post(
      environment.url + url,
      formData,
      {}).toPromise();
  }
  POST_FILE(url, data): any {
    return new Promise((resolve, reject) => {
      this.http.post(environment.url + url, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
  GET(url): any {
    return new Promise(async resolve => {
      this.http.get(environment.url + url).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  POST(url, data?): any {
    return new Promise((resolve, reject) => {
      this.http.post(environment.url + url, data, {headers: {'Content-Type': 'application/json'}}).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
  setItem(name, item) {
    window.localStorage.setItem(name, JSON.stringify(item));
  }
  removeItem(name) {
    window.localStorage.removeItem(name);
  }
  getItem(name): any {
    return JSON.parse(window.localStorage.getItem(name));
  }
  GET_URL(url) {
    return new Promise(async resolve => {
      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  setLoggedUser(loggedUser){
    this.loggedUser = loggedUser;
    this.setItem('loggedUser',loggedUser);
  }
  setMenu(menues:Menu[]){
    this.menues = menues;
    this.setItem('menues',menues);
  }
  getMenu(module): Menu{
    for(const menu of this.menues){
      if(menu.childrens){
        for(const child of menu.childrens){
          if (child.url === module) {
            return child;
          }
        }
      }else {
        if (menu.url === module) {
          return menu;
        }
      }
    }
  }
  async getDataUrlFromFile(file){
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    })
  }
  getColor(color:string){
    if(color === 'Rojo'){
      return 'danger';
    }else if(color === 'Naranja'){
      return 'warning';
    }else if(color === 'Verde'){
      return 'success';
    }
  }
  removeFromList(list, obj){
    let index = list.indexOf(obj);
    if(index > -1){
      list.splice(index, 1);
    }
    return list;
  }
  isDateBefore(date){
    if(moment(date).isBefore(new Date())){
      return true;
    }else{
      return false;
    }
  }

  goTo(selector){
    $('html, body').animate({
      scrollTop: $(selector).offset().top
    }, 1000);
  }
  getDefaultDate(days?) {
    const date = new Date();
    const year = date.getFullYear();
    const day  = date.getDate() + days;
    const month  = date.getMonth() + 1;
    const formattedMonth = month > 9 ? month.toString() : '0' + month;
    const curDay  = day > 9 ? day.toString() : '0' + day;
    return year + '-' + formattedMonth + '-' + curDay;
  }
  getDefaultDateTime(selectedHour?) {
    const date = new Date();
    const year = date.getFullYear();
    const day  = date.getDate();
    const month  = date.getMonth() + 1;
    const formattedMonth = month > 9 ? month.toString() : '0' + month;
    const curDay  = day > 9 ? day.toString() : '0' + day;
    const hour = selectedHour ? selectedHour : '18:00';
    return year + '-' + formattedMonth + '-' + curDay + 'T' + hour;
  }

  arrayToString(arr){
    let arrStr = '';
    if(arr) {
      for (const arrObj of arr) {
        if (arrStr === '') {
          arrStr = arrObj;
        } else {
          arrStr += ',' + arrObj;
        }
      }
    }
    return arrStr;
  }
  generateRamdomString(length: number): string{
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  copyObject(object){
    return JSON.parse(JSON.stringify(object));
  }
  getFieldValue(field, fields: Field[]){
    for (const tmpField of fields){
      if (tmpField.field === field){
        return tmpField.value;
      }
    }
  }
}
