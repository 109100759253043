<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_prestamo_form" autocomplete="off" *ngIf="prestamo.cliente">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Nombres cliente:</label>
          <input [(ngModel)]="prestamo.cliente.nombres" type="text" name="nombres" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del usuario" readonly/>
                 <span class="form-text text-muted">Nombres del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Apellidos cliente:</label>
          <input [(ngModel)]="prestamo.cliente.apellidos" type="text" name="apellidos" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del usuario" readonly/>
                 <span class="form-text text-muted">Apellidos del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Email:</label>
          <input [(ngModel)]="prestamo.cliente.email"
                 autocomplete="off" type="text" name="email" class="form-control form-control-solid" placeholder="Email del usuario" readonly/>
                 <span class="form-text text-muted">Email del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Teléfono:</label>
          <input [(ngModel)]="prestamo.cliente.whatsapp_phone" type="text" name="telefono" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Telefono del usuario" readonly/>
                 <span class="form-text text-muted">Teléfono del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Monto solicitado:</label>
          <input [(ngModel)]="prestamo.monto" type="text" name="monto" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Monto" readonly/>
                 <span class="form-text text-muted">Monto solicitado por el usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Monto a pagar:</label>
          <input [(ngModel)]="prestamo.monto_final" type="text" name="monto_final" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Monto final" readonly/>
                 <span class="form-text text-muted">Monto a pagar por el usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Tasa:</label>
          <input [(ngModel)]="prestamo.tasa" type="text" name="tasa" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Tasa" readonly/>
                 <span class="form-text text-muted">Tasa diaria estimada</span>
        </div>
        <div class="col-lg-6">
          <label>Intereses:</label>
          <input [(ngModel)]="prestamo.interes" type="text" name="interes" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Interes" readonly/>
                 <span class="form-text text-muted">Intereses a pagar por el usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Cantidad de días:</label>
          <input [(ngModel)]="prestamo.dias" type="text" name="dias" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Dias" readonly/>
                 <span class="form-text text-muted">Cantidad de días a pagar</span>
        </div>
        <div class="col-lg-6">
          <label>Nombre banco:</label>
          <input [(ngModel)]="prestamo.cliente.nombre_banco" type="text" name="nombre_banco" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Dias" readonly/>
                 <span class="form-text text-muted">Nombre del banco a depositar</span>
        </div>
        <div class="col-lg-6">
          <label>No. Cuenta:</label>
          <input [(ngModel)]="prestamo.cliente.no_cuenta" type="text" name="no_cuenta" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Dias" readonly/>
                 <span class="form-text text-muted">Número de cuenta a depositar</span>
        </div>
        <div class="col-lg-6">
          <label>Estado:</label>
          <select class="form-control" name="role_id" [(ngModel)]="prestamo.estado_id" *ngIf="estados" [ngClass]="{'readonly': prestamo.estado_id == 2}" (ngModelChange)="estadoChanged($event)">
            <option value="" selected>Seleccione</option>
            <option [value]="data.id" *ngFor="let data of estados" >{{data.estado}}</option>
          </select>
          <span class="form-text text-muted">Estado del prestamo</span>
        </div>

      </div>

      <div class="col-lg-12" style="margin: auto;" *ngIf="cuotas">
        <table class="table row-border hover">
          <thead class="bg-gray-100" *ngIf="cuotas.length > 0">
          <tr>
            <th>Monto a pagar</th>
            <th>Fecha</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let cuota of cuotas">
            <td>
              {{ cuota.monto | currency:"RD$" }}
            </td>

            <td>{{ cuota.fecha }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-lg-12" style="margin: auto;" *ngIf="cuotas_active">
        <table class="table row-border hover">
          <thead class="bg-gray-100" *ngIf="cuotas_active.length > 0">
          <tr>
            <th>Monto a pagar</th>
            <th>Fecha</th>
            <th>Estado</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let cuota of cuotas_active">
            <td>
              {{ cuota.monto | currency:"RD$" }}
            </td>

            <td>{{ cuota.fecha_pago }}</td>
            <td>
              <div [ngSwitch]="cuota.estado">
                <div *ngSwitchCase="1">PAGADO</div>
                <div *ngSwitchDefault>PENDIENTE</div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>