import { Component, OnInit } from '@angular/core';
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;

@Component({
  selector: 'app-tipo-juego-add-or-edit',
  templateUrl: './tipo-juego-add-or-edit.component.html',
  styleUrls: ['./tipo-juego-add-or-edit.component.css']
})
export class TipoJuegoAddOrEditComponent implements OnInit {
  modulo = 'tipo de juego';
  table   = 'tipo_juegos'
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  tipo_juego:any;
  combinacion: any;
  ActionType: ActionType;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  posicionesData:any;
  posiciones:any=[];
  nuevas_posiciones:any;
  selecteds:any = [];
  combinaciones_exist:boolean;
  combinaciones:any = [];
  combinaciones_selecteds:any = [];
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      this.tipo_juego.id = parseInt(params.get('id'));

      // Obtener las posiciones
      const parms = {
        table: 'posiciones'
      };
      
      this.posicionesData = await this.req.POST('data', parms)??[];

      if(this.tipo_juego.id){
        const response = await this.req.GET('tipo_juego?module=' + this.table + '&id=' + this.tipo_juego.id);
        var res = response.response;

        if(res.combinaciones.length > 0){
            var combinacion_a = [];
          for (let i = 0; i < res.combinaciones.length; i++) {
            const combinacion = res.combinaciones[i];

            if(combinacion.detalle.length > 0){
              combinacion_a.push(combinacion.detalle);
            } 
          }
          this.combinaciones.push({"combinaciones":combinacion_a});

          if (this.combinaciones) {
            this.combinaciones_exist = true;
          }

          console.log(this.combinaciones)
        }


        this.selecteds = response.response.posiciones;
        this.posiciones =this.selecteds.map(p=>p.id);
        this.tipo_juego = response.response;
        this.accion = ActionType.EDIT;  

      }
      if(ActionType.CREATE === this.accion) {
        this.tipo_juego.estado = 'Activo';
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_login_signin_form'),
        {
          fields: {
            nombre: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerida.'
                }
              }
            },
            cantidad_jugadas: {
              validators: {
                notEmpty: {
                  message: 'Cantidad de jugadas es requerido.'
                }
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
  async guardar(){
    const status = await this.validation.validate(); 
    let combinaciones = [];
    for(let i = 0;i < this.combinaciones[0].combinaciones.length; i++){
      combinaciones.push(this.combinaciones[0].combinaciones[i].map(p=>p.id));
    }
    var data = {
      "tipo_juego":this.tipo_juego,
      "posiciones":this.posiciones,
      "combinaciones":combinaciones
    };
    if (ValidForm.VALID === status){
      this.loadingSave  = true;
      const response    = await this.req.POST('save_tipo_juego', data);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('tipo-juego');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente unc ' + this.modulo + '.</p>',
          'success'
        );


      }
    }
  }

  generarCombinaciones(){
    var posiciones = this.posicionesData.filter(posicion=>this.posiciones.includes(posicion.id));

    this.combinaciones = [];
    // Por cada tipo de juego obtendremos sus combinaciones de jugadas

        var combinacion = [];
        var t_combinacion = this.tipo_juego.cantidad_numeros;
        for (let c = 0; c < posiciones.length; c++) {
          var numero = posiciones[c];

          // numero.push([{"nombre":res.response.nombre}]);
          combinacion.push(numero);
        }

        this.combinaciones.push(
          {
            "combinaciones":this.combination(combinacion, t_combinacion)
          });


    if (this.combinaciones) {
      this.combinaciones_exist = true;
    }

  }

  combination(array, n){
    if(n === 1) return array.map(x => [x])

    if(n === 2) return array.flatMap((x, i) =>  {
        return array.slice(i+1).map(y => [x, y])
    })

    if(n === 3) return array.flatMap((x, i) => {
        return array.slice(i + 1).flatMap((y, j) => {
            return array.slice(i + j + 2).map(z => [x, y, z])
        })
    })

  }

  agregarCombinacion(){
    const nuevas_posiciones = this.selecteds.filter(p=>this.nuevas_posiciones.includes(p.id));
    this.combinaciones[0].combinaciones.push(nuevas_posiciones);
    this.nuevas_posiciones = null;
  }

  deleteCombination(index){
    this.combinaciones[0].combinaciones.splice(index, 1);
  }

}
